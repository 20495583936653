import { Link } from "@remix-run/react"
import LigonierLogo from "app/icons/LigonierLogo"

export function Footer() {
  return (
    <div className="bg-lightslate pt-20 md:pt-12 pb-10">
      <div className="flex flex-col items-center sm:items-start pb-10 lg:items-start justify-evenly lg:justify-center mx-auto  sm:flex-row sm:max-w-full lg:max-w-6xl">
        <div id="column1" className="lg:w-full lg:flex lg:justify-evenly">
          <div className="flex flex-col mb-5 sm:mb-0 lg:py-5 lg:px-5">
            <LigonierLogo
              className="flex mx-auto sm:mx-8 lg:mx-0"
              width={43}
              height={39}
            />
          </div>

          <div className="flex flex-col text-center sm:text-left sm:px-8 sm:pt-8 lg:p-5 min-w-fit">
            <h2 className="text-primary-500 text-base mt-0 mb-2 capitalize font-avenir font-medium">
              Welcome
            </h2>
            <ul className="list-none pl-0 m-0 mb-7 lg:mb-5 text-sm text-footer-gray lg:text-darkpeach leading-relaxed">
              <li>
                <a href="https://www.ligonier.org/about">
                  About Ligonier Ministries
                </a>
              </li>
            </ul>

            <h2 className="text-primary-500 text-base mt-0 mb-2 capitalize font-avenir font-medium">
              Ministries
            </h2>
            <ul className="list-none pl-0 m-0 mb-7 sm:mb-0 text-sm text-footer-gray lg:text-darkpeach leading-relaxed">
              <li>
                <a
                  href="https://tabletalkmagazine.com"
                  aria-label="Read more on the official Tabletalk Magazine website"
                >
                  Tabletalk Magazine
                </a>
              </li>
              <li>
                <a
                  href="https://renewingyourmind.org"
                  aria-label="Visit the official Renewing Your Mind website"
                >
                  Renewing Your Mind
                </a>
              </li>
              <li>
                <a href="https://connect.ligonier.org">Ligonier Connect</a>
              </li>
              <li>
                <a
                  href="https://reformationstudybible.com"
                  aria-label="Visit the official Reformation Study Bible website"
                >
                  Reformation Study Bible
                </a>
              </li>
              <li>
                <a href="https://reformationbiblecollege.org">
                  Reformation Bible College
                </a>
              </li>
              <li>
                <a href="https://refnet.fm">RefNet</a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col text-center sm:text-left sm:px-8 sm:pt-8 lg:p-5 min-w-fit ">
            <h2 className="text-primary-500 text-base mt-0 mb-2 capitalize font-avenir font-medium">
              Teaching
            </h2>
            <ul className="list-none pl-0 m-0 mb-7 lg:mb-5 text-sm text-footer-gray lg:text-darkpeach leading-relaxed">
              <li>
                <a
                  href="http://www.ligonier.org/learn/series"
                  aria-label="Watch or listen to teaching series on Ligonier.org"
                >
                  Teaching Series
                </a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/conferences">
                  Conference Messages
                </a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/qas">
                  Questions & Answers
                </a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/devotionals">
                  Devotionals
                </a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/articles">Articles</a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/sermons">Sermons</a>
              </li>
              <li>
                <a href="https://www.ligonier.org/podcasts">Podcasts</a>
              </li>
            </ul>

            <h2 className="text-primary-500 text-base mt-0 mb-2 capitalize font-avenir font-medium">
              Indexes
            </h2>
            <ul className="list-none pl-0 m-0 mb-7 sm:mb-0 text-sm text-footer-gray lg:text-darkpeach leading-relaxed">
              <li>
                <a href="https://www.ligonier.org/learn/topics">Topics</a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/teachers">Teachers</a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/scripture">
                  Scriptures
                </a>
              </li>
              <li>
                <a href="https://www.ligonier.org/learn/collections">
                  Resource Collections
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="column2"
          className="lg:w-full lg:flex lg:justify-evenly sm:pt-8 lg:p-0"
        >
          <div className="flex flex-col text-center sm:text-left sm:px-8 sm:pt-8 min-w-fit lg:p-5">
            <h2 className="text-primary-500 text-base mt-0 mb-2 capitalize font-avenir font-medium">
              Store
            </h2>
            <ul className="list-none pl-0 m-0 mb-7 sm:mb-0 text-sm text-footer-gray lg:text-darkpeach leading-relaxed">
              <li>
                <a
                  href="https://ligonier.ca/store"
                  aria-label="Visit the official Ligonier Ministries store"
                >
                  Store Home
                </a>
              </li>
              <li>
                <a
                  href="https://reformedbookservices.com/collections/reformation-study-bible"
                  aria-label="Shop for Bibles on Ligonier.org"
                >
                  Bibles
                </a>
              </li>
              <li>
                <a
                  href="https://reformedbookservices.com/collections/ligonier-teaching-series"
                  aria-label="Shop for teaching series on Ligonier.org"
                >
                  Teaching Series
                </a>
              </li>
              <li>
                <a
                  href="https://reformedbookservices.com/collections/ligonier-books"
                  aria-label="Shop for books on Ligonier.org"
                >
                  Books
                </a>
              </li>
              <li>
                <a
                  href="https://reformedbookservices.com/collections/ligonier-childrens-books"
                  aria-label="Shop for children's books on Ligonier.org"
                >
                  Children
                </a>
              </li>
              <li>
                <a
                  href="https://reformedbookservices.com/collections/ligonier-music"
                  aria-label="Shop for music on Ligonier.org"
                >
                  Music
                </a>
              </li>
              <li>
                <a
                  href="https://store.ligonier.org/type/tabletalk"
                  aria-label="Shop for Tabletalk Magazines on Ligonier.org"
                >
                  Tabletalk Magazine
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col text-center sm:text-left sm:px-8 sm:pt-8 lg:p-5 min-w-fit">
            <h2 className="text-primary-500 text-base mt-0 mb-2 capitalize font-avenir font-medium">
              Give
            </h2>
            <p className="text-footer-gray text-sm leading-relaxed text-footer-gray mt-0 lg:text-darkpeach max-w-[280px] mx-auto md:mx-0">
              By God’s grace and with your support, more people will be reached
              with God’s mind-renewing and life-transforming Word.
            </p>

            <div className="mb-8 bg-[#353430] mx-auto border border-[#555] text-[#a6a196] sm:mx-0 inline-block text-center uppercase tracking-wider text-xs px-3 py-1.5 max-w-fit lg:bg-transparent lg:border-white lg:text-white">
              <Link to="/donate">Donate</Link>
            </div>

            <h2 className="text-primary-500 text-base mt-0 mb-2 capitalize font-avenir font-medium">
              Contact Us
            </h2>
            <ul className="list-none pl-0 m-0 mb-7 sm:mb-0 text-sm text-footer-gray lg:text-darkpeach leading-relaxed">
              <li>
                <a href="mailto:service@ligonier.ca">Email Us</a>
              </li>
              <li>
                <a
                  href="https://lc.chat/now/7423931/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Chat
                </a>
              </li>
              <li>
                Call Toll Free: <a href="tel:+18007330408">800-733-0408</a>
              </li>
              <li>PO Box 1200, Ayr, ON N0B 1E0 </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mx-auto px-10 max-w-6xl text-sm text-footer-gray flex flex-col items-center lg:flex-row lg:justify-between">
        <div className="order-2 lg:order-1">
          &copy; Ligonier Ministries Canada {new Date().getFullYear()}
        </div>
        <div className="mb-6 lg:mb-0 order-1 lg:order-2">
          <a href="https://www.ligonier.org/privacy-policy">Privacy Policy</a>
        </div>
      </div>
    </div>
  )
}
