import { useState } from "react"
import { Link } from "@remix-run/react"
import LigonierLogoAndWordmark from "app/icons/LigonierLogoAndWordmark"

export type IsMobileMenuOpen = boolean

export function Navigation() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] =
    useState<IsMobileMenuOpen>(false)

  const closeMenu = () => {
    setIsMobileMenuOpen(false)
  }

  // Define a variable for menu classes to handle the transition (instead of conditional hidden and block visiblity)
  const menuClasses = `absolute m-0 p-0 pb-4 list-none inset-x-0 top-0 bg-nav-gray text-white z-20 flex flex-col items-center transition-opacity duration-500 ease-in-out ${
    isMobileMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
  }`

  return (
    <div className="bg-primary-500 w-full flex">
      <nav
        role="navigation"
        className="flex w-full items-center justify-between h-16 mx-auto max-w-6xl"
      >
        <Link to="/" className="flex pl-5" aria-label="ligonier-logo">
          <LigonierLogoAndWordmark className="w-auto fill-white" />
        </Link>

        <ul className="hidden lg:flex space-x-14 mr-5 text-base">
          <li className="flex items-center text-white text-center uppercase font-avenir-bold tracking-wider">
            <a rel="noopener noreferrer" href="/store">
              Store
            </a>
          </li>
          <li className="flex items-center text-white text-center uppercase font-avenir-bold tracking-wider">
            <Link to="/church-partnership">Church Partnership</Link>
          </li>
          <li className="flex items-center text-white uppercase font-avenir-bold tracking-wider text-center">
            <Link to="/faq">FAQ</Link>
          </li>
          <li className="flex items-center text-white uppercase font-avenir-bold tracking-wider">
            <Link to="/donate" className="border-2 border-white px-10 py-1">
              Donate
            </Link>
          </li>
        </ul>

        <button
          className={`cursor-pointer inline-block pr-5 max-w-10 bg-transparent border-none lg:hidden ${
            isMobileMenuOpen ? "open" : ""
          }`}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label="menu-toggle"
        >
          <div className="h-[3px] w-5 bg-white mb-1"></div>
          <div className="h-[3px] w-5 bg-white mb-1"></div>
          <div className="h-[3px] w-3 bg-white mb-1"></div>
        </button>
      </nav>

      <ul className={menuClasses}>
        <button
          className="px-6 py-2.5 self-end text-white cursor-pointer text-3xl bg-transparent border-none"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          x
        </button>
        <li className="p-4">
          <a
            className="text-2xl text-white"
            onClick={closeMenu}
            href="/store"
            rel="noopener noreferrer"
          >
            Store
          </a>
        </li>
        <li className="p-4">
          <Link
            to="/church-partnership"
            className="text-2xl text-white"
            onClick={closeMenu}
          >
            Church Partnership
          </Link>
        </li>
        <li className="p-4">
          <Link to="/faq" className="text-2xl text-white" onClick={closeMenu}>
            FAQ
          </Link>
        </li>
        <li className="p-4">
          <Link
            to="/donate"
            className="text-2xl text-white"
            onClick={closeMenu}
          >
            Donate
          </Link>
        </li>
      </ul>
    </div>
  )
}
